jQuery(function($) {

    // show cities when clicking on state -- desktop
    $('g[data-marker="true"]').on('click', function() {
        var ID =  $(this).attr('id');
        var state = $('.'+ID+'-cities');
        $('.cities-content').css('display', 'none');
        $('.active-state').css('display', 'none');
        state.addClass('active-state');
        state.fadeIn();
        fitty('.active-state .map-city-title', {
            minSize: 2,
            maxSize: 50,
            multiLine: false
        });
    });

    //show cities when selecting a state from dropdown -- mobile
    $('#state_select').on('change', function() {
        var ID = $(this).val();
        $('.cities-content').css('display', 'none');
        $('.'+ID+'-cities').fadeIn();
    });


});